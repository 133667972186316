/**
 * getEventHostingPlatformByRole
 */
function getEventHostingPlatformByRole (userRole) {
  switch (userRole) {
    case "USERROLE11115":
    case "USERROLE11116":
      return {
        "ZOOM": "ZOOM",
        "ZOOM_LEAD": "ZOOM_LEAD",
        "OTHER": "OTHER"
      }
    default:
      return {
        "ZOOM": "ZOOM",
        "ZOOM_LEAD": "ZOOM_LEAD",
        "HSM_ZOOM": "HSM_ZOOM",
        "HSM_GEC_INPERSON": "HSM_GEC_INPERSON",
        "HSM_GEC_VIRTUAL": "HSM_GEC_VIRTUAL",
        "HSM_TOUR": "HSM_TOUR",
        "HSM_EDUCATOR_RETREAT": "HSM_EDUCATOR_RETREAT",
        "HSM_EDUCATOR_DINNER": "HSM_EDUCATOR_DINNER",
        "HSM_CONNECT": "HSM_CONNECT",
        "HSM_CONCLAVE": "HSM_CONCLAVE",
        "GOOGLE_MEET": "GOOGLE_MEET",
        "MS_TEAMS": "MS_TEAMS",
        "DISCORD": "DISCORD",
        "QUIZ_COMPETITION": "QUIZ_COMPETITION",
        "DOWNLOAD_LINK": "DOWNLOAD_LINK",
        "OTHER": "OTHER"
      }
  }
}
export default getEventHostingPlatformByRole
